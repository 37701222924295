
  <v-app v-bind:style="$store.state.settings.darkTheme ? { background: $vuetify.theme.themes.dark.background } : {}">
<!--  <v-app >-->
    <v-navigation-drawer v-show="$route.name !== 'Register' && $route.name !== 'Login'"
                         :color="$store.state.settings.darkTheme ? '#19444F' : ''" app v-model="drawer">
      <div style="background: #00303B">
        <v-img
            class="mx-auto py-0 pointer"
            src="/img/pz_logo_orange.png"
            height="64"
            max-width="200"
            contain
            @click="$router.push('/')"
        ></v-img>


      </div>
      <v-divider></v-divider>

      <v-list
          dense
          nav
      >
        <v-list-item-group>
          <v-list-item
              id="t_dashboard"
              @click="$router.push('/')"
              color="primary"
              link
          >
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('dashboard-heading') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item

              id="t_credits"
              link
              @click="$router.push({ name: 'Documents'})"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-document-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('documents-heading') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--          <v-list-item-->
          <!--              link-->
          <!--              @click="$router.push({ name: 'PolicyViolations'})"-->
          <!--              color="primary"-->
          <!--          >-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-alert-circle-check</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>{{$t('policy-violations-heading')}}</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->
          <v-list-item
              id="t_msg"
              link
              @click="$router.push({ name: 'Messages'})"

          >
            <v-list-item-icon>
              <v-icon>mdi-message</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('messages-heading') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              link
              @click="$router.push({ name: 'Account'})"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('settings-account') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              link
              @click="$router.push({ name: 'Reports'})"
              v-if="$store.getters.userHasReportAccess"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-table</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('reports') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              link
              @click="$router.push({ name: 'Partner'})"
              v-if="$store.getters.getShowVendors"
          >
            <v-list-item-icon>
              <v-icon>mdi-handshake</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('partner') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              prepend-icon="mdi-wrench"
              no-action
              id="t_adstxt"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t('setup-heading') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                link
                @click="$router.push({ name: 'AdsTxt'})"
            >
              <v-list-item-icon>
                <v-icon>mdi-text-box-check-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('adstxt-heading') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!--            <v-list-item-->
            <!--                link-->
            <!--                @click="$router.push({ name: 'Tags'})"-->
            <!--                disabled-->
            <!--            >-->
            <!--              <v-list-item-icon>-->
            <!--                <v-icon disabled>mdi-code-tags</v-icon>-->
            <!--              </v-list-item-icon>-->
            <!--              <v-list-item-content>-->
            <!--                <v-list-item-title>{{$t('tags-heading')}}</v-list-item-title>-->
            <!--              </v-list-item-content>-->
            <!--            </v-list-item>-->


          </v-list-group>
          <v-list-item
              link
              @click="$router.push({ name: 'Settings'})"
              color="primary"
          >
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('settings-heading') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <!--          Chart test-->

          <!--          <v-list-item-->
          <!--              link-->
          <!--              @click="$router.push({ name: 'Debug'})"-->
          <!--              color="primary"-->
          <!--          >-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-chart-areaspline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Chart test</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

        </v-list-item-group>
      </v-list>

      <v-list nav dense>

        <!--        <v-list-item>-->
        <!--          <v-list-item-content>-->
        <!--            <v-select-->
        <!--                dense-->
        <!--                v-if="$store.getters.languageList"-->
        <!--                :items="$store.getters.languageList"-->
        <!--                :item-text="item => item.name"-->
        <!--                :item-value="item => item"-->
        <!--                v-model="selectedLanguage"-->
        <!--                dense-->
        <!--                label="Language"-->
        <!--                filled-->
        <!--                @input="changeLocale"-->

        <!--            >-->
        <!--            </v-select>-->
        <!--          </v-list-item-content>-->
        <!--        </v-list-item>-->
      </v-list>

      <template v-slot:append>
        <np-footer></np-footer>
      </template>


    </v-navigation-drawer>
    <v-app-bar
        app
        flat
        dark
        color="#00303B"
        clipped-right
        v-show="$route.name !== 'Register' && $route.name !== 'Login'"

    >
      <v-btn
          text
          icon
          @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>

      </v-btn>
      <v-img
          id="t_settings"
          v-show="!drawer"
          class="mx-2 pointer"
          src="/img/pz_transparent.png"
          max-height="60"
          max-width="200"
          contain
          @click="$router.push('/')"
      ></v-img>

      <h4 v-if="$route.name === 'Dashboard'">{{ $t('dashboard-looking-at') }}
        {{ $store.getters.getFormatedTimeframe }}</h4>

      <v-spacer></v-spacer>

      <v-btn
          v-show="!this.$auth.currentUser"
          @click="$router.push('register')"
          text
      >
        <span class="mr-2">{{ $t('register-button') }}</span>
      </v-btn>

      <v-tooltip v-if="!this.$auth.currentUser" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              @click="$router.push('login')"
              v-bind="attrs"
              v-on="on"
          >

            <v-icon>mdi-login</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('login-button') }}</span>
      </v-tooltip>

      <v-menu
          :close-on-content-click="false"
          offset-x

      >
        <template #activator="{ on: onMenu, attrs: attrsMenu }">

          <v-tooltip  bottom>
            <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                  id="t_notifications"
                  icon
                  v-bind="{...attrsMenu, ...attrsTooltip}"
                  v-on="{ ...onMenu, ...onTooltip }"
                  :disabled="!$store.state.communication.notifications.length > 0"
              >
                <v-badge
                    overlap
                    color="primary"
                    v-show="$store.getters.getUnseenNotificationNumber > 0"
                    :content="$store.getters.getUnseenNotificationNumber.toString()"
                >
                  <v-icon>mdi-bell</v-icon>
                </v-badge>
                <v-icon v-show="!$store.getters.getUnseenNotificationNumber > 0">mdi-bell</v-icon>
              </v-btn>

            </template>
            <span v-if="$store.getters.getUnseenNotificationNumber === 0">{{ $t('no-new-notifications') }}</span>
            <span v-else>{{ $t('click-to-see-notifications') }}</span>
          </v-tooltip>
        </template>
        <notification-menu></notification-menu>
      </v-menu>

      <v-tooltip v-if="this.$auth.currentUser" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="signOut()">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('logout-button') }}</span>
      </v-tooltip>


    </v-app-bar>
    <v-main style="background: rgba(0,48,59,0.07)" v-if="$route.name !== 'Register' && $route.name !== 'Login'">
      <router-view/>
    </v-main>

    <router-view v-if="$route.name === 'Register' || $route.name === 'Login'"/>

    <!--    <np-tour :steps="t_steps" :max="t_steps.length -1"></np-tour>-->
  </v-app>
