<template>
  <v-app v-bind:style="$store.state.settings.darkTheme ? { background: $vuetify.theme.themes.dark.background } : {}">
<!--  <v-app >-->
    <v-navigation-drawer v-show="$route.name !== 'Register' && $route.name !== 'Login'"
                         :color="$store.state.settings.darkTheme ? '#19444F' : ''" app v-model="drawer">
      <div style="background: #00303B">
        <v-img
            class="mx-auto py-0 pointer"
            src="/img/pz_logo_orange.png"
            height="64"
            max-width="200"
            contain
            @click="$router.push('/')"
        ></v-img>


      </div>
      <v-divider></v-divider>

      <v-list
          dense
          nav
      >
        <v-list-item-group>
          <v-list-item
              id="t_dashboard"
              @click="$router.push('/')"
              color="primary"
              link
          >
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('dashboard-heading') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item

              id="t_credits"
              link
              @click="$router.push({ name: 'Documents'})"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-document-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('documents-heading') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--          <v-list-item-->
          <!--              link-->
          <!--              @click="$router.push({ name: 'PolicyViolations'})"-->
          <!--              color="primary"-->
          <!--          >-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-alert-circle-check</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>{{$t('policy-violations-heading')}}</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->
          <v-list-item
              id="t_msg"
              link
              @click="$router.push({ name: 'Messages'})"

          >
            <v-list-item-icon>
              <v-icon>mdi-message</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('messages-heading') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              link
              @click="$router.push({ name: 'Account'})"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('settings-account') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              link
              @click="$router.push({ name: 'Reports'})"
              v-if="$store.getters.userHasReportAccess"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-table</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('reports') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              link
              @click="$router.push({ name: 'Partner'})"
              v-if="$store.getters.getShowVendors"
          >
            <v-list-item-icon>
              <v-icon>mdi-handshake</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('partner') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              prepend-icon="mdi-wrench"
              no-action
              id="t_adstxt"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t('setup-heading') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                link
                @click="$router.push({ name: 'AdsTxt'})"
            >
              <v-list-item-icon>
                <v-icon>mdi-text-box-check-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('adstxt-heading') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!--            <v-list-item-->
            <!--                link-->
            <!--                @click="$router.push({ name: 'Tags'})"-->
            <!--                disabled-->
            <!--            >-->
            <!--              <v-list-item-icon>-->
            <!--                <v-icon disabled>mdi-code-tags</v-icon>-->
            <!--              </v-list-item-icon>-->
            <!--              <v-list-item-content>-->
            <!--                <v-list-item-title>{{$t('tags-heading')}}</v-list-item-title>-->
            <!--              </v-list-item-content>-->
            <!--            </v-list-item>-->


          </v-list-group>
          <v-list-item
              link
              @click="$router.push({ name: 'Settings'})"
              color="primary"
          >
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('settings-heading') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <!--          Chart test-->

          <!--          <v-list-item-->
          <!--              link-->
          <!--              @click="$router.push({ name: 'Debug'})"-->
          <!--              color="primary"-->
          <!--          >-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon>mdi-chart-areaspline</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Chart test</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

        </v-list-item-group>
      </v-list>

      <v-list nav dense>

        <!--        <v-list-item>-->
        <!--          <v-list-item-content>-->
        <!--            <v-select-->
        <!--                dense-->
        <!--                v-if="$store.getters.languageList"-->
        <!--                :items="$store.getters.languageList"-->
        <!--                :item-text="item => item.name"-->
        <!--                :item-value="item => item"-->
        <!--                v-model="selectedLanguage"-->
        <!--                dense-->
        <!--                label="Language"-->
        <!--                filled-->
        <!--                @input="changeLocale"-->

        <!--            >-->
        <!--            </v-select>-->
        <!--          </v-list-item-content>-->
        <!--        </v-list-item>-->
      </v-list>

      <template v-slot:append>
        <np-footer></np-footer>
      </template>


    </v-navigation-drawer>
    <v-app-bar
        app
        flat
        dark
        color="#00303B"
        clipped-right
        v-show="$route.name !== 'Register' && $route.name !== 'Login'"

    >
      <v-btn
          text
          icon
          @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>

      </v-btn>
      <v-img
          id="t_settings"
          v-show="!drawer"
          class="mx-2 pointer"
          src="/img/pz_transparent.png"
          max-height="60"
          max-width="200"
          contain
          @click="$router.push('/')"
      ></v-img>

      <h4 v-if="$route.name === 'Dashboard'">{{ $t('dashboard-looking-at') }}
        {{ $store.getters.getFormatedTimeframe }}</h4>

      <v-spacer></v-spacer>

      <v-btn
          v-show="!this.$auth.currentUser"
          @click="$router.push('register')"
          text
      >
        <span class="mr-2">{{ $t('register-button') }}</span>
      </v-btn>

      <v-tooltip v-if="!this.$auth.currentUser" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              @click="$router.push('login')"
              v-bind="attrs"
              v-on="on"
          >

            <v-icon>mdi-login</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('login-button') }}</span>
      </v-tooltip>

      <v-menu
          :close-on-content-click="false"
          offset-x

      >
        <template #activator="{ on: onMenu, attrs: attrsMenu }">

          <v-tooltip  bottom>
            <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                  id="t_notifications"
                  icon
                  v-bind="{...attrsMenu, ...attrsTooltip}"
                  v-on="{ ...onMenu, ...onTooltip }"
                  :disabled="!$store.state.communication.notifications.length > 0"
              >
                <v-badge
                    overlap
                    color="primary"
                    v-show="$store.getters.getUnseenNotificationNumber > 0"
                    :content="$store.getters.getUnseenNotificationNumber.toString()"
                >
                  <v-icon>mdi-bell</v-icon>
                </v-badge>
                <v-icon v-show="!$store.getters.getUnseenNotificationNumber > 0">mdi-bell</v-icon>
              </v-btn>

            </template>
            <span v-if="$store.getters.getUnseenNotificationNumber === 0">{{ $t('no-new-notifications') }}</span>
            <span v-else>{{ $t('click-to-see-notifications') }}</span>
          </v-tooltip>
        </template>
        <notification-menu></notification-menu>
      </v-menu>

      <v-tooltip v-if="this.$auth.currentUser" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="signOut()">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('logout-button') }}</span>
      </v-tooltip>


    </v-app-bar>
    <v-main style="background: rgba(0,48,59,0.07)" v-if="$route.name !== 'Register' && $route.name !== 'Login'">
      <router-view/>
    </v-main>

    <router-view v-if="$route.name === 'Register' || $route.name === 'Login'"/>

    <!--    <np-tour :steps="t_steps" :max="t_steps.length -1"></np-tour>-->
  </v-app>
</template>

<script>
import NotificationMenu from "@/components/notification-menu.vue";
import NpSidebar from "@/components/dashboard/np-sidebar.vue";
import NpFooter from "@/components/np-footer.vue";
import NpTour from "./components/np-tour";


export default {
  name: 'App',
  components: {NpTour, NpFooter, NotificationMenu, NpSidebar},
  data() {
    return {
      drawer: null,
      t_steps: [
        {
          target: '#t_settings',
          title: this.$t('tour_pref_title'),
          content: [
            this.$t('tour_pref_1'),
            this.$t('tour_pref_2'),
          ],
          site: "Settings"
        },
        {
          target: '#t_dashboard',
          title: this.$t('tour_dashboard_title'),
          content: [
            this.$t('tour_dashboard_1'),
            this.$t('tour_dashboard_2'),
          ],
          site: "Dashboard"
        },
        {
          target: '#t_timesel',
          title: this.$t('tour_timesel_title'),
          content: [
            this.$t('tour_timesel'),
          ],
          site: "Dashboard"
        },
        {
          target: '#t_filter',
          title: this.$t('tour_filter_title'),
          content: [
            this.$t('tour_filter'),
          ],
          site: "Dashboard"
        },
        {
          target: '#t_split',
          title: this.$t('tour_split_title'),
          content: [
            this.$t('tour_split'),
          ],
          site: "Dashboard"
        },
        {
          target: '#t_sparklines',
          title: this.$t('tour_sparklines_title'),
          content: [
            this.$t('tour_sparklines'),
          ],
          site: "Dashboard"
        },
        {
          target: '#t_charts',
          title: this.$t('tour_charts_title'),
          content: [
            this.$t('tour_charts'),
          ],
          site: "Dashboard"
        },
        {
          target: '#t_table',
          title: this.$t('tour_table_title'),
          content: [
            this.$t('tour_table'),
          ],
          site: "Dashboard"
        },
        {
          target: '#t_export',
          title: this.$t('tour_export_title'),
          content: [
            this.$t('tour_export'),
          ],
          site: "Dashboard"
        },
        {
          target: '#t_credits',
          title: this.$t('tour_credits_title'),
          content: [
            this.$t('tour_credits'),
          ],
          site: "Documents"
        },
        {
          target: '#t_msg',
          title: this.$t('tour_msg_title'),
          content: [
            this.$t('tour_msg'),
          ],
          site: "Messages"
        },
        {
          target: '#t_adstxt',
          title: this.$t('tour_adstxt_title'),
          content: [
            this.$t('tour_adstxt'),
          ],
          site: "AdsTxt"
        },
        // {
        //   target: '#t_notifications',
        //   title: this.$t('tour_notifications_title'),
        //   content: this.$t('tour_notifications')
        // },
        {
          target: '#t_dashboard',
          title: this.$t('tour_end_title'),
          content: [
            this.$t('tour_end')
          ],
          site: "Dashboard"
        },

      ]
    }
  },
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = this.selectedLanguage.lang
    },
    currentUser() {
      return this.$auth.currentUser
    },
    signOut() {
      this.$auth.signOut()
      this.$router.push("/login")
    },
  },
  computed: {
    selectedLanguage: {
      get() {
        return this.$store.state.lang.selectedLanguage
      },
      set(value) {
        this.$store.dispatch("setSelectedLanguage", value)
      }
    },

    navbarWidth() {
      if (this.$route.name === "Dashboard") {
        if (this.$store.state.statistics.sidebarDrawer === true) return "71%";
        else return "100%";

      }


    }


  },

  mounted() {

    this.$store.commit("generateUUID")
    this.$store.dispatch("fetchDomains")
    this.$store.dispatch("fetchLanguages")
    this.$store.dispatch("fetchNotifications")
    this.$store.dispatch("fetchMetadata")
    //todo: change this when indexdb caching works
    this.$vuetify.theme.dark = this.$store.state.settings.darkTheme
  },


};
</script>
<style>
@import "assets/css/materialdesignicons.min.css";
@import "assets/css/np-fonts.css";


div#app {
  font-family: "Akkurat LL", serif !important;
}

body {
  font-family: "Akkurat LL", serif !important;
}

.v-application .text-h6, .v-application .text-body-1, .v-application .text-h5, .v-application .text-h4, .v-application .text-h3, .v-application .text-h2, .v-application .text-h1 {
  font-family: "Akkurat LL", serif !important;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

#tour {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
}


</style>
